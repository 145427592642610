import React from "react";
import classNames from "classnames";
import { Carousel, CardFundraiser } from "@molecules";
import { Container, Text, Button } from "@atoms";

const CarouselFundraisers = ({ heading, copy, actions }) => {
  return (
    <div className="overflow-hidden bg-brown py-12 md:py-24">
      <Container>
        <div className="flex flex-col">
          <div className="text-center">
            <Text variant="h3" className="text-gold">
              {heading}
            </Text>
            <Text variant="body--large" className="text-white">
              {copy}
            </Text>
          </div>
          <div className="mt-8 md:mt-12">
            {actions?.length > 2 && (
              <div className="-mx-3">
                <Carousel
                  showMultiple
                  maxVisible={3}
                  gradient="brown"
                  centerItems
                >
                  {actions.map((fundraiser, index) => (
                    <div className="flex h-full px-3">
                      <CardFundraiser centered {...fundraiser} />
                    </div>
                  ))}
                </Carousel>
              </div>
            )}
            {actions?.length <= 2 && (
              <div className="-mx-3 flex flex-wrap justify-center space-y-6 md:space-y-0">
                {actions.map((fundraiser, index) => (
                  <div className="w-full px-3 md:w-1/3">
                    <CardFundraiser centered {...fundraiser} />
                  </div>
                ))}
              </div>
            )}
            {!actions?.length && (
              <div
                className={classNames(
                  "font-sans flex w-full flex-col items-center justify-center text-center font-bold text-red",
                  {}
                )}
              >
                <div className="mb-2 text-white">Be the first to</div>
                <div>
                  <Button to="/create-fundraiser">Create a Fundraiser</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

CarouselFundraisers.defaultProps = {};

export default CarouselFundraisers;
